import parse from 'html-react-parser'
import Blocks from '@/components/molecules/Blocks'
import PropTypes from 'prop-types'

/**
 * Stackable Container Columns
 *
 * A .
 *
 * @author RKC
 * @param  {object}  props                 The component properties.
 * @param  {object}  props.innerBlocks     The array of inner blocks to display.
 * @param  {string}  props.originalContent Original prerendered content.
 * @return {Element}                       The Feature component.
 */
export default function StkBlockColumns({innerBlocks, originalContent}) {
  const options = {
    replace: ({attribs, children}) => {
      if (!attribs) {
        return
      }
      // Replace the source Wordpress media URL with S3 URL
      if (children && children.length) {
        children.forEach((element) => {
          if (element.type === 'text') {
            element.data = element.data.replace(
              'http://sharqinsurance.web2box.com/wp-content',
              'https://s3.eu-west-1.amazonaws.com/web2box.com'
            )
            return element
          }
        })
      }
      const classes = attribs.class
      if (classes && classes.includes('stk-inner-blocks')) {
        return (
          <>
            <div className={classes}>
              {!!innerBlocks?.length && <Blocks blocks={innerBlocks} />}
            </div>
          </>
        )
      }
    }
  }
  return <>{parse(originalContent, options)}</>
}

StkBlockColumns.propTypes = {
  innerBlocks: PropTypes.arrayOf(
    PropTypes.shape({
      block: PropTypes.object,
      index: PropTypes.number
    })
  ),
  originalContent: PropTypes.string
}
